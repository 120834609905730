// Login.js
import React, { useState } from 'react';
import firebaseApp from '../config/firebase';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, setPersistence, browserSessionPersistence } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setPersistence(auth, browserSessionPersistence)
          .then(
            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                // User logged in successfully
                const user = userCredential.user;
                console.log('User logged in:', user);
                // Additional actions after login (e.g., redirect, display success message)
                navigate('/home');
              })
              .catch((error) => {
                // Handle login error
                if (error.code == 'auth/invalid-login-credentials') {
                  setError('Invalid Password');
                } else if (error.code == 'auth/invalid-email') {
                  setError('Invalid Email');
                } else if (error.code == 'auth/missing-password') {
                  setError('Missing Password')
                } else if (error.code == 'auth/network-request-failed') {
                  setError('Connection Error')
                } else {
                  setError(error.message);
                }
              })
          ).catch((error) => {
            setError(error.message);
          });
    } catch (error) {
      // Handle login errors (e.g., display an error message)
      setError('Error logging in:', error.code, error.message);
    }
  };

  return (
    <div className='login-container'>
      <h2 className='login-title'>Login</h2>
      <form className='login-form-container'>
          <label for="email" className='login-lable'>Email:</label>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='login-input'
            id='email'
          />
          <label for="password" className='login-lable'>Password:</label>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='login-input'
            id='password'
          />
          {error && <p className='error'>{error}</p>}
          <input type='submit' onClick={e => {handleLogin(e)}} className='login-button' value='Login' />
      </form>
    </div>
  );
};

export default Login;