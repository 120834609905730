import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Home from '../functions/home_function';
import Fullscreen from '../functions/fullscreen_function';

const HomePage = () => {

  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/upload');
      }
    });
  
    return () => unsubscribe();
  }, [user]);


  return (
    <div>
      <Home />
      <Fullscreen />
    </div>
  );
};

export default HomePage;