// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3RUT_FHpZpHY5zatvpY7ovVbj-vuimtc",
  authDomain: "graphiccollection-96c78.firebaseapp.com",
  projectId: "graphiccollection-96c78",
  storageBucket: "graphiccollection-96c78.appspot.com",
  messagingSenderId: "541926368599",
  appId: "1:541926368599:web:bf133148e3ce1be9230a12",
  measurementId: "G-W9H3X4T04S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);