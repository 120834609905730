import React from "react";

function Project(props) {
    const product = props.data;
    const fContainer = document.getElementById("fullscreen-container");
    const fImage = document.getElementById("fullscreen-image");

    const formatUploadTime = (timestamp) => {
        const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        
        // Extract day, month, and year from the date
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based, so add 1
        const year = date.getFullYear();
    
        // Format day, month, and year to a string
        const formattedDate = `${day}.${month}.${year}`;

        return formattedDate;
      };

    const displayFull = () => {
        fContainer.style.display = "block";
        fImage.style.backgroundImage = 'url(' + product.photoURL + ')';
    }

    //<a href={product.photoURL} target="_blank">download</a>
    
    return (
        <div onClick={displayFull} className='product-image' style={{backgroundImage: 'url(' + product.photoURL + ')'}}>
            <div className='product-content'>
                <div className={'product-info-container'}></div>
            </div>
        </div>
    )
  }

  export default Project;