import { useState, useEffect, useRef, useCallback } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { _tags } from '../config/tags'; 


const Upload = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [uid, setUID] = useState("");
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [display, setDisplay] = useState('none');
  const [tags, setTags] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userUID = user.uid;
        setUID(userUID);
      } else {
        navigate('/home');
      }
    });

    return () => unsubscribe();
  }, [user, auth, navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
        fileInputRef.current.click(); // Trigger the file input click event
      }
  }

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;

      // Check if the file type is allowed (example: allow only images)
      if (!fileType.startsWith('image/')) {
        // Display an error message or prevent further processing
        setError('Invalid file type. Please select an image.');
        e.target.value = "";
        return;
      }

      if(selectedFile.size > 2097152){
        setError("File is too big, limit 2MB");
        e.target.value = "";
        return;
     }
    }
    setImage(selectedFile);

    // Show image preview
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleUpload = async () => {
    if (tags.length <= 0) {
      setError('Please enter at least one tag');
      return;
    } else if (!image) {
      setError('Please upload an image');
      return;
    }

    setError(null);
    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `products/${image.name}`);

    await uploadBytes(storageRef, image);
    const photoURL = await getDownloadURL(storageRef);
    // Get the image URL from Firebase Storage if needed

    const db = getFirestore();
    const productsCollection = collection(db, 'products');


    try {
      const uploadTime = Timestamp.now();
      const tagObj = tags.reduce((acc, tag) => {
        acc[tag] = true;
        return acc;
      }, {});

      const newProductRef = await addDoc(productsCollection, {
        uid,
        tags: tagObj,
        uploadTime,
        photoURL,
      });
      console.log('Product uploaded with ID:', newProductRef.id);
      setSuccess(true);
      // Handle the image upload to Firebase Storage here if needed
    } catch (error) {
      console.error('Error adding product:', error);
    }

  };

  const resetUpload = () => {
    setSelectedTag('');
    setSearchInput('');
    setTags([]);
    setUploading(false);
    setSuccess(false);
    setImage(null); // Reset the image state to null
    setImagePreview(null);
  }

  const handleAddTag = useCallback(() => {
    if (tags.length > 14) {
      setError('Too many tags');
      return;
    }

    if (selectedTag && !tags.includes(selectedTag)) {
      setTags(prevTags => [...prevTags, selectedTag.toLowerCase()]);
      setSelectedTag('');
    }
  }, [selectedTag, tags]);

  useEffect(() => {
    handleAddTag();
  }, [selectedTag, handleAddTag]);

  useEffect(() => {
    if(searchInput.length > 0) {
      setDisplay('block');
    } else {
      setDisplay('none');
    }
  },[searchInput]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const clearSearchTerms = () => {
    setTags([]);
  }

  const removeLi = (tag) => {
    setTags(tags.filter(e => e !== tag));
  }

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    setSearchInput('');
  };

  return (
    <div className='upload-container'>
        {!uploading && <div className='upload-image-container'>
            <h2 onClick={handleImageClick} className='upload-file'>Upload Image</h2>
            <div className='upload-image-preview' style={{backgroundImage: 'url(' + imagePreview + ')'}}>
              <label for="file-upload">
                <input id="file-upload" className='hidden' type="file" ref={fileInputRef} onChange={handleImageChange}/>
                Select Picture
              </label>
            </div>
        </div>}
        <div className='upload-description-container'>
            <div className='upload-description'>
              {!uploading ? (
                <div>
                  <div>
                    <h2>Search Tags</h2>
                    <div className='upload-search-reco-container'>
                      <input
                        className='upload-searchbar'
                        type="text"
                        placeholder="Search tags"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                      <ul className='recomended-tags upload' style={{ display: display}}>
                        {_tags
                          .filter((tag) => tag.toLowerCase().startsWith(searchInput.toLowerCase()))
                          .map((tag) => (
                            <li
                              key={tag}
                              onClick={() => handleTagClick(tag)}
                            >
                              {tag}
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div>
                      <h3 className='upload-added-tags'>Added Tags</h3>
                      <ul className='added-tags-ul'>
                        <div className='added-clear'>
                          <li key="clear" onClick={clearSearchTerms}>clear</li>
                        </div>
                        {tags.map((tag) => (
                          <div className='li-container'>
                            <li key={tag}>{tag}</li>
                            <div className='li-remove' onClick={() => {removeLi(tag)}}>X</div>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <div className='upload-button-container'>
                    <button onClick={handleUpload}>Upload</button>
                    <button className='logout-button' id='logout-button' onClick={handleLogout}>Logout</button>
                  </div>
                </div>
              ) : (
                <div>
                  {error ? (
                    <div>
                      <h2>{"Etwas ist schief gelaufen :("}</h2>
                      {error && <button onClick={() => {setUploading(false)}}>Neu Probieren</button>}  
                    </div>
                  ) : (
                    <div>
                      { success ? <div className='upload-success'><h2>Erfolgreich Heraufgeladen</h2></div> : <h2>Uploading...</h2>}
                      { success && <button id='success-button' onClick={resetUpload}>Neues Bild</button>}
                    </div>
                  )}
                </div>
              )}
            </div>
        </div>
    </div>
  );
};

export default Upload;
