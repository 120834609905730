import React from "react";

function Fullscreen() {

    const hideFullscreen = (e) => {
        e.target.style.display = "none";
        e.target.firstChild.src = '';
    }

    return (
        <div className='fullscreen-container' id="fullscreen-container" onClick={e => (hideFullscreen(e))}>
            <div className='fullscreen-image' id="fullscreen-image"></div>
        </div>
    )
  }

  export default Fullscreen;