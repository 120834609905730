import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, query, where, limit } from 'firebase/firestore';
import Product from './product_function';
import { _tags } from '../config/tags';

const Home = () => {
  const [search, setSearch] = useState('');
  const [searchTerms, setSearchTerms] = useState([]);

  const db = getFirestore();
  const productsCollection = collection(db, 'products');

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [display, setDisplay] = useState('none');
  const minWidth = 200;
  const [maxWidth, setMaxWidth] = useState(0);
  const [products, setProducts] = useState([]);
  const [hasProducts, setHasProducts] = useState(false);

  useEffect(() => {
    fetchProducts();
  },[searchTerms]);

  const fetchProducts = async () => {
    let q = productsCollection;
    if(searchTerms.length !== 0) {
      const whereClauses = searchTerms.map(tag => where(`tags.${tag}`, '==', true));
      q = query(productsCollection, ...whereClauses, limit(15));
      //q = query(productsCollection, where("tags.color", "==", true));
    }

    try {
      const querySnapshot = await getDocs(q);
      const productsData = [];
      querySnapshot.forEach((doc) => {
        productsData.push({ id: doc.id, ...doc.data() });
      });
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const searchTag = (tag) => {
    if(tag && !searchTerms.includes(tag)) {
      setSearchTerms(prevTags => [...prevTags, tag.toLowerCase()]);
      setSearch('');
    }
  }

  const clearSearchTerms = () => {
    setSearchTerms([]);
  }

  const removeLi = (tag) => {
    setSearchTerms(searchTerms.filter(e => e !== tag));
  }

  if(!hasProducts) {
    fetchProducts();
    setHasProducts(true);
  }

  useEffect(() => {
    if(search.length > 0) {
      setDisplay('block');
    } else {
      setDisplay('none');
    }
  },[search]);

  useEffect(() => {
    // Update screenWidth state when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const count = Math.floor(screenWidth / minWidth)
    setMaxWidth(100 / count);
  },[screenWidth])

  return (
    <div>
      <div className='home-search-container'>
        <div className='home-search-box'>
          <div className='home-searchbar'>
            <input
              type="text"
              placeholder="color, typo, poster"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='home-search'
            />
          </div>
        </div>
        <div className='added-tags'>
          <div className='added-ui-container'>
            <h3>Added Tags</h3>
          </div>
          <ul className='added-tags-ul'>
            <div className='added-clear'>
              <li key="clear" onClick={clearSearchTerms}>clear</li>
            </div>
            {searchTerms.map((tag) => (
              <div className='li-container'>
                <li key={tag}>{tag}</li>
                <div className='li-remove' onClick={() => {removeLi(tag)}}>X</div>
              </div>
            ))}
          </ul>
        </div>
        <ul className='recomended-tags' style={{ display: display}}>
              {search === '' ? <div></div> :
                _tags
                  .filter((tag) => tag.toLowerCase().startsWith(search.toLowerCase()))
                  .map((tag) => (
                <li
                  key={tag}
                  value={tag}
                  onClick={e => {searchTag(e.currentTarget.getAttribute('value'))}}
                >
                  {tag}
                </li>
              ))}
          </ul>
      </div>
      <div className='box-text text-left'>
        <div>
            <div className='products-container' style={{ gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, ${maxWidth}%))` }}>
              {products.map((product) => (
                  <Product data={product} key={product.id}/>
              ))}
            </div>
        </div>
      </div>
    </div>
  );
};

export default Home;