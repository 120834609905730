import React from 'react';
import Upload from '../functions/upload_function';


const UploadPage = () => {
  return (
    <div>
      <Upload />
    </div>
  );
};

export default UploadPage;