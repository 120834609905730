export let _tags = [
    'color',
    'typography',
    'minimalism',
    'vibrant',
    'illustration',
    'branding',
    'logo',
    'poster',
    'layout',
    'web design',
    'print design',
    'digital art',
    'creative',
    'modern',
    'vintage',
    'flat design',
    'vector',
    'pattern',
    'texture',
    'composition',
    'contrast',
    'symmetry',
    'asymmetry',
    'geometric shapes',
    'gradients',
    'photomanipulation',
    'retro',
    'hipster',
    'urban',
    'hand lettering',
    'calligraphy',
    'sketch',
    'infographic',
    'user interface',
    'user experience',
    'mobile design',
    'responsive design',
    'iconography',
    'motion graphics',
    'animation',
    'collage',
    'futuristic',
    'sci-fi',
    'technology',
    'inspirational quotes',
    'nature-inspired',
    'minimalistic',
    'whitespace',
    'editorial design',
    'publication',
    'packaging design',
    'creative process',
    'behance',
    'dribbble',
    'pinterest',
    'social media graphics',
    'digital marketing',
    'art direction',
    'color theory',
    'layout design',
    'creative agency',
    'portfolio',
    'photography',
    'mockup',
    'trendy',
    'vintage typography',
    'modern retro',
    'artistic',
    'monochromatic',
    'abstract',
    'product design',
    'app design',
    'UI/UX trends',
    'responsive web design',
    'editorial layout',
    'creative studio',
    'creative block',
    'inspiration boards',
    'artistic expression',
    'visual identity',
    'design trends',
    'experimental design',
    'digital illustration',
    'brand identity',
    'editorial illustration',
    'motion design',
    'print advertising',
    'storytelling in design',
    'poster design inspiration',
    'visual hierarchy',
    'dynamic typography',
    'conceptual design',
    'photo manipulation',
    'design thinking',
    'creativity boost',
  ];